<template>
  <div class="table-sort__head">
    <div class="table-sort__row first-head">
      <div class="table-sort__th column-icon"></div>
      <div class="table-sort__th column-number">
        <p>№</p>
      </div>
      <div class="table-sort__th column-code" @click="changeSorting(0)">
        <p :class="getClassSorting(0)">{{ columnCode }}</p>
      </div>
      <div class="table-sort__th column-name-product" @click="changeSorting(1)">
        <p :class="getClassSorting(1)">{{ columnNameProduct }}</p>
      </div>
      <div class="table-sort__th column-trade-type" @click="changeSorting(2)">
        <p :class="getClassSorting(2)">{{ columnTradeType }}</p>
      </div>
      <div class="table-sort__th column-departure-country" @click="changeSorting(3)">
        <p :class="getClassSorting(3)">{{ columnDepartureCountry }}</p>
      </div>
      <div class="table-sort__th column-destination-country" @click="changeSorting(4)">
        <p :class="getClassSorting(4)">{{ columnDestinationCountry }}</p>
      </div>
      <div v-if="columnKindTransport" class="table-sort__th column-kind-transport" @click="changeSorting(5)">
        <p :class="getClassSorting(5)">{{ columnKindTransport }}</p>
      </div>
      <div class="table-sort__th column-status" @click="changeSorting(6)">
        <p :class="getClassSorting(6)">{{ columnStatus }}</p>
      </div>
      <div class="table-sort__th column-date-creation" @click="changeSorting(7)">
        <p :class="getClassSorting(7)">{{ columnDateCreation }}</p>
      </div>
      <div class="table-sort__th column-date-change" @click="changeSorting(8)">
        <p :class="getClassSorting(8)">{{ columnDateChange }}</p>
      </div>
    </div>
    <div class="table-sort__row second-head">
      <div class="table-sort__th column-icon"></div>
      <div class="table-sort__th column-number"></div>
      <div class="table-sort__th column-code">
        <ComponentInput
          placeholder=""
          mod="filter-small"
          type="number"
          v-model="getCodeFilter"
          clearButton
          @onClear="clearCodeFilter"
          :error="v$.getCodeFilter.maxLength.$invalid"
          :maxLength="12"
        />
      </div>
      <div class="table-sort__th column-name-product">
        <ComponentInput
          placeholder="Найти"
          mod="filter-small"
          v-model="getNameProductFilter"
          clearButton
          @onClear="clearNameProductFilter"
        />
      </div>
      <div class="table-sort__th column-trade-type">
        <SelectMultiComponent
          v-model="getTradeTypeFilter"
          :options="getTypeOfTradingOperationMultiSelect"
          placeholder="Все"
          classSelect="multiselect--rectangular multiselect--filter-small visible-clear"
        />
      </div>
      <div class="table-sort__th column-departure-country">
        <SelectMultiComponentCheckbox
          v-model="getDepartureCountryFilter"
          :options="getCountriesSelect"
          trackBy="text"
          labelOptions="text"
          valueProp="id"
          placeholder="Все"
          mod="filter-small"
          :disabled="disabledDepartureCountry"
        />
      </div>
      <div class="table-sort__th column-destination-country">
        <SelectMultiComponentCheckbox
          v-model="getDestinationCountryFilter"
          :options="getCountriesSelect"
          trackBy="text"
          labelOptions="text"
          valueProp="id"
          placeholder="Все"
          mod="filter-small"
          :disabled="disabledDestinationCountry"
        />
      </div>
      <div v-if="columnKindTransport" class="table-sort__th column-kind-transport">
        <SelectMultiComponentCheckbox
          v-model="getKindTransportFilter"
          :options="getTransportList"
          trackBy="text"
          labelOptions="text"
          valueProp="id"
          placeholder="Все"
          mod="filter-small"
        />
      </div>
      <div class="table-sort__th column-status">
        <SelectMultiComponent
          v-model="getStatusFilter"
          :options="getOptionsStatusList"
          placeholder="Все"
          classSelect="multiselect--rectangular multiselect--filter-small visible-clear"
        />
      </div>
      <div class="table-sort__th column-date-creation">
        <dateComponent
          v-model="getDateCreationFromFilter"
          dateIcon
          modClass="filter-small"
          :upperLimit="upperLimitCreationFrom"
        />
        <dateComponent
          v-model="getDateCreationToFilter"
          dateIcon
          modClass="filter-small"
          :upperLimit="upperLimitTo"
          :lowerLimit="lowerLimitCreationTo"
        />
      </div>
      <div class="table-sort__th column-date-change">
        <dateComponent
          v-model="getDateChangeFromFilter"
          dateIcon
          modClass="filter-small"
          :upperLimit="upperLimitChangeFrom"
        />
        <dateComponent
          v-model="getDateChangeToFilter"
          dateIcon
          modClass="filter-small"
          :upperLimit="upperLimitTo"
          :lowerLimit="lowerLimitChangeTo"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { maxLength } from '@vuelidate/validators';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import SelectMultiComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectMultiComponentCheckbox.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';

  import { TRADING_OPERATION_ID } from '../../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    components: { SelectMultiComponentCheckbox, dateComponent, ComponentInput, SelectMultiComponent },
    emits: [
      'update:codeFilter',
      'update:nameProductFilter',
      'update:tradeTypeFilter',
      'update:departureCountryFilter',
      'update:destinationCountryFilter',
      'update:statusFilter',
      'update:dateCreationFromFilter',
      'update:dateCreationToFilter',
      'update:dateChangeFromFilter',
      'update:dateChangeToFilter',
      'changeSorting',
      'changeFilterDepartureCountry',
      'changeFilterDestinationCountry',
    ],
    props: {
      columnCode: {
        type: String,
        required: true,
      },
      columnNameProduct: {
        type: String,
        required: true,
      },
      columnTradeType: {
        type: String,
        required: true,
      },
      columnDepartureCountry: {
        type: String,
        required: true,
      },
      columnDestinationCountry: {
        type: String,
        required: true,
      },
      columnKindTransport: {
        type: String,
      },
      columnStatus: {
        type: String,
        required: true,
      },
      columnDateCreation: {
        type: String,
        required: true,
      },
      columnDateChange: {
        type: String,
        required: true,
      },
      codeFilter: {
        type: [String, Number],
        required: true,
      },
      nameProductFilter: {
        type: [String],
        required: true,
      },
      tradeTypeFilter: {
        type: [String, Number],
        required: true,
      },
      departureCountryFilter: {
        type: Array,
        required: true,
      },
      destinationCountryFilter: {
        type: Array,
        required: true,
      },
      kindTransportFilter: {
        type: Array,
        default: () => [],
      },
      statusFilter: {
        type: [String, Number],
        required: true,
      },
      dateCreationFromFilter: {
        type: String,
        required: true,
      },
      dateCreationToFilter: {
        type: String,
        required: true,
      },
      dateChangeFromFilter: {
        type: String,
        required: true,
      },
      dateChangeToFilter: {
        type: String,
        required: true,
      },
      publicSortColumn: {
        type: Number,
        required: true,
      },
      publicSortOrder: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        optionsStatusList: Constants.statusOptions,
        upperLimitTo: new Date(),
        disabledDepartureCountry: false,
        disabledDestinationCountry: false,
      };
    },
    computed: {
      ...mapGetters({
        getCountriesSelect: DIRECTIVES_GETTERS_NAME.getCountriesSelect,
        getTypeOfTradingOperationMultiSelect: DIRECTIVES_GETTERS_NAME.getTypeOfTradingOperationMultiSelect,
        getTransportList: DIRECTIVES_GETTERS_NAME.getTransportList,
      }),

      getOptionsStatusList() {
        return this.optionsStatusList.map((i) => ({ value: i.id, label: i.text }));
      },

      getCodeFilter: {
        get() {
          return this.codeFilter;
        },
        set(value) {
          this.$emit('update:codeFilter', value);
        },
      },
      getNameProductFilter: {
        get() {
          return this.nameProductFilter;
        },
        set(value) {
          this.$emit('update:nameProductFilter', value);
        },
      },
      getTradeTypeFilter: {
        get() {
          return this.tradeTypeFilter;
        },
        set(value) {
          this.$emit('update:tradeTypeFilter', value);

          if (value === +TRADING_OPERATION_ID.export) {
            this.disabledDepartureCountry = true;
          } else if (this.disabledDepartureCountry) {
            this.disabledDepartureCountry = false;
          }

          if (value === +TRADING_OPERATION_ID.import) {
            this.disabledDestinationCountry = true;
          } else if (this.disabledDestinationCountry) {
            this.disabledDestinationCountry = false;
          }
        },
      },
      getDepartureCountryFilter: {
        get() {
          return this.departureCountryFilter;
        },
        set(value) {
          this.$emit('update:departureCountryFilter', value);
        },
      },
      getDestinationCountryFilter: {
        get() {
          return this.destinationCountryFilter;
        },
        set(value) {
          this.$emit('update:destinationCountryFilter', value);
        },
      },
      getKindTransportFilter: {
        get() {
          return this.kindTransportFilter;
        },
        set(value) {
          this.$emit('update:kindTransportFilter', value);
        },
      },
      getStatusFilter: {
        get() {
          return this.statusFilter;
        },
        set(value) {
          this.$emit('update:statusFilter', value);
        },
      },
      getDateCreationFromFilter: {
        get() {
          return this.dateCreationFromFilter;
        },
        set(value) {
          this.$emit('update:dateCreationFromFilter', value);
        },
      },
      getDateCreationToFilter: {
        get() {
          return this.dateCreationToFilter;
        },
        set(value) {
          this.$emit('update:dateCreationToFilter', value);
        },
      },
      getDateChangeFromFilter: {
        get() {
          return this.dateChangeFromFilter;
        },
        set(value) {
          this.$emit('update:dateChangeFromFilter', value);
        },
      },
      getDateChangeToFilter: {
        get() {
          return this.dateChangeToFilter;
        },
        set(value) {
          this.$emit('update:dateChangeToFilter', value);
        },
      },

      upperLimitCreationFrom() {
        if (this.getDateCreationToFilter) {
          return moment(this.getDateCreationToFilter, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitCreationTo() {
        return moment(this.getDateCreationFromFilter, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
      upperLimitChangeFrom() {
        if (this.getDateChangeToFilter) {
          return moment(this.getDateChangeToFilter, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitChangeTo() {
        return moment(this.getDateChangeFromFilter, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
    created() {
      this.loadOptions();
    },
    methods: {
      ...mapActions({
        countriesGet: DIRECTIVES_ACTIONS_NAME.countriesGet,
        getTypeOfTradingOperation: DIRECTIVES_ACTIONS_NAME.getTypeOfTradingOperation,
        getTransport: DIRECTIVES_ACTIONS_NAME.getTransport,
      }),

      async loadOptions() {
        await this.countriesGet();
        await this.getTypeOfTradingOperation();

        if (this.columnKindTransport) {
          await this.getTransport();
        }
      },

      getClassSorting(id) {
        if (this.publicSortColumn == id) {
          if (this.publicSortOrder == 0) return 'ascending';
          else if (this.publicSortOrder == 1) return 'descending';
        }
        return 'default';
      },

      changeSorting(id) {
        this.$emit('changeSorting', id);
      },

      clearCodeFilter() {
        this.$emit('update:codeFilter', '');
      },

      clearNameProductFilter() {
        this.$emit('update:nameProductFilter', '');
      },
    },
    watch: {
      disabledDepartureCountry(val) {
        this.$emit('changeFilterDepartureCountry', val);
      },
      disabledDestinationCountry(val) {
        this.$emit('changeFilterDestinationCountry', val);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        getCodeFilter: { maxLength: maxLength(12) },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-sort.scss';
</style>
